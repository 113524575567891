<template>
  <HeadlessDisclosure v-slot="{ open }" as="nav" class="sticky top-0 z-10 border-b border-gray-200 bg-white shadow">
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex">
          <div class="flex flex-shrink-0 items-center">
            <NuxtImg class="h-10" height="40" :src="logo" :alt="company?.name" />
          </div>
          <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
            <NuxtLink
              v-for="item in currentNavigation"
              :key="item.name"
              :to="item.href"
              :rel="item.rel"
              :target="item.target"
              :class="[
                item.current
                  ? 'border-meevo-blue-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
              ]"
              :aria-current="item.current ? 'page' : undefined"
              >{{ item.name }}</NuxtLink
            >
          </div>
        </div>
        <div class="-mr-2 flex items-center sm:hidden">
          <HeadlessDisclosureButton
            class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-meevo-blue-500 focus:ring-offset-2"
          >
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </HeadlessDisclosureButton>
        </div>
      </div>
    </div>
    <HeadlessDisclosurePanel class="sm:hidden">
      <div class="space-y-1 pb-3 pt-2">
        <HeadlessDisclosureButton
          v-for="item in currentNavigation"
          :key="item.name"
          :as="NuxtLink"
          :to="item.href"
          :rel="item.rel"
          :target="item.target"
          :class="[
            item.current
              ? 'border-meevo-blue-500 bg-blue-50 text-meevo-blue-700'
              : 'border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800',
            'block border-l-4 py-2 pl-3 pr-4 text-base font-medium'
          ]"
          :aria-current="item.current ? 'page' : undefined"
          >{{ item.name }}</HeadlessDisclosureButton
        >
      </div>
    </HeadlessDisclosurePanel>
  </HeadlessDisclosure>
</template>
<script setup lang="ts">
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'

const NuxtLink = resolveComponent('nuxt-link')
const { company, companySlug, website, logo } = useMarketplace()

const navigation = computed(() => {
  return [
    {
      name: 'zu ' + new URL(website.value).hostname.replace('www.', ''),
      href: website.value || '#',
      current: false,
      rel: 'noopener',
      target: '_blank'
    },
    { name: 'zurück zum Start', href: { name: 'slug', params: { slug: companySlug.value } }, current: false }
  ]
})

const currentNavigation = computed(() =>
  navigation.value.filter((item) => item.href !== useRoute().path && item.href !== '#')
)
</script>
